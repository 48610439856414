.loading__wrap{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    display: grid;
    place-items: center;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s ease-out, z-index 0s ease .5s;
    &.transparent{
        background-color: #ffffffde;
        backdrop-filter: blur(10px);
    }
    &.show{
        opacity: 1;
        z-index: 1000;
    }
}