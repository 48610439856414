.product__banner{
    $height: 400px;
    padding: 30px 0px 0px 0px;  
    // @media screen and (max-width: 575px) {
    //     $height: 300px
    // }
    // background-image: url('./bg.webp');
    background-size: cover;
    // text-align: center;
    background-position: center;
    // min-height: $height;
    img{
        pointer-events: none;
    }
    .chefjoy{
        width: 115px;
        margin-bottom: 1em;
        pointer-events: none
    }
    .center__div{
        min-height: $height;
        display: grid;
        place-content: center;
        place-items: center;
    }
    .text-24px {
        @media(max-width: 768px) {
            font-size: 20px;
        }
    }
    .text-spacing-min-1-2px {
        @media(max-width: 768px) {
            letter-spacing: -0.4px;
        }
    }
}