.card{
    background-color: white;
    text-align: left;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: all .5s ease-in-out;
    &:hover{
        img{
            transform: scale(1.05);
        }
        h5{
            color: #ff9921;
        }
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        transform: scale(1.0125);
        background-color: #8DC73F;
        h5, 
        .content, 
        .icon,
        p {
            color: white;
        }
    }
    .icon {
        margin-right: 12px;
        color: #5AA18E;
    }
    h5{
        font-size: 24px;
        letter-spacing: -1.2px;
        color: #5aa18e;
        transition: all .35s ease;
        display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
        min-height: 2.5em;
        line-height: 1.2;
        @media screen and (max-width: 575px) {
            min-height: unset;
		    -webkit-line-clamp: 3;
        }
    }
    p{
        margin-bottom: 0;
    }
    .image{
        aspect-ratio: 4 / 3;
        overflow: hidden;
        // margin-bottom: 1em;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .35s ease;
        pointer-events: none;
    }
    .content{
        padding: 1.5em 2em;
        *{
            transition: all .3s ease;
        }
    }
}