$green: #bccb4c;
$greenSage: #5AA18E;
$black: #444;
$orange: #ff9921;
$blackLight: #666666;
$grey: #a8a8a8;
footer{
    // color: white;
    font-size: 14px;
    border-top: 1px solid #EAEAEA;
    overflow: hidden;
    // background-image: linear-gradient(180deg, #d7fbb3 -80%, transparent 80%);
    .container{
        padding-top: 50px;
        padding-bottom: 50px;
        @media screen and (max-width: 767px) {
            padding-bottom: 0;
            .copyright{
                font-size: 10px;
            }
        }
    }
    .row>div{
        // border-bottom: 1px solid #d27c0f;
        &:nth-child(2){
            border: none;
        }
    }
    h5 {
        font-size: 16px;
        font-weight: 700;
    }
    .copyright{
        text-align: center;
        font-size: 14px;
        line-height: 48px;
        padding: 6px 0;
        background-color: #8DC73F;
        color: white;
        a{
            &:hover{
                text-decoration: underline;
            }
        }
    }
    a{
        // color: white;
    }
    img{
        width: 250px;
        margin-bottom: 1em;
        @media screen and (max-width: 575px) {
            width: 150px;
            margin-bottom: 0;
        }
    }
    .list-footer{
        padding: 0;
        list-style: none;
        &.uppercase{
            li{
                text-transform: uppercase;
            }
            @media screen and (max-width: 767px) {
                column-count: 2;
            }
        }
        li{
            margin-bottom: 10px;
            margin-left: 0;
            display: flex;
            align-items: center;
            i {
                margin-right: 8px;
            }
            .fal{
                width: 25px;
            }
            &.active{
                a{
                    font-weight: bold;
                }
            }
            a{
                font-size: 14px;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    background-color: $greenSage;
                    width: 0;
                    transition: all .35s ease;
                }
                &:hover:before{
                    width: 100%;
                }
            }
        }
    }
    p{
        font-size: 14px;
        line-height: 30px;
    }
}