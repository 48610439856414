$green: #bccb4c;
$greenSage: #5aa18e;
$darkgreen: #4B8474;
$black: #444;
$orange: #ff9921;
$blackLight: #666666;
$grey: #a8a8a8;
.sliderWrap {
    width: 100%;
    position: relative;
    @media(min-width: 600px) {
        height: inherit;
        min-height: 100%;
        max-height: 100%;
    }
    @media(min-width: 900px) {
        min-height: 400px;
        max-height: 700px;
    }
    @media(min-width: 1200px) {
        // height: 85vh;
        min-height: 400px;
        max-height: 700px;
    }
    .sliderDiv {
        position: absolute;
        width: 75%;
        z-index: 1;
        top: 0;
        height: 100%;
        left: 0;
        background-color: white;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            height: 85vh;
        }
        .main-slider {
            height: 100%;
        }
        .product-title{
            color: $greenSage;
            margin-bottom: 1em;
        }
        .desc-div{
            position: absolute;
            width: 40%;
            width: 50%;
            left: 5em;
            bottom: 4em;
            padding: 2em;
            background-color: rgba( 255,255,255, 0.9);
            backdrop-filter: blur(2px);
        }
        .product-image{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: inline-block;
            position: absolute !important;
            top: 0;
            left: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media screen and (max-width: 767px) {
                aspect-ratio: 4 / 3;
                height: unset;
            }
        }
        .product-slider{
            height: 100%;
        }
        .product-desc{
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }
    .contentDiv {
        // position: absolute;
        // width: 30%;
        z-index: 2;
        top: -7%;
        height: auto;
        // max-height: 100%;
        right: 0;
        // background-color: $green;
        padding: 2.5em 3em;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        $space: 30px;
        // height: -webkit-fill-available;
        // overflow-y: scroll;
        font-size: .85rem;
        ul {
            margin-left: $space;
            padding: 0;
            list-style: none;
            li {
                padding-left: 1.25em;
                margin-bottom: .75em;
                position: relative;
                cursor: pointer;
                transition: all 0.35s ease;
                &:hover {
                    color: $orange;
                }
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    border: 2px solid white;
                    background-color: transparent;
                    transition: all 0.5s ease;
                }
                &.active {
                    &:before {
                        background-color: $orange;
                    }
                }
            }
        }
        .listNumber {
            width: $space;
        }
        @media screen and (max-width: 767px) {
            padding: 2em 1em;
            ul{
                margin-left: 1em;
            }
        }
    }
    @media screen and (max-width: 767px) {
        height: auto;
        max-height: unset;
        .sliderDiv, .contentDiv{
            position: relative;
            width: auto;
        }
        .sliderDiv{
            .desc-div{
                left: 1em;
                width: calc(100% - 2em);
                border: 1px solid #efefef;
    box-shadow: 0px 0 15px -3px rgba(0, 0, 0, 0.1);

            }
        }
        .contentDiv{
            // display: none;
        }
    }
}

.product-wrap {
    position: relative;
    p {
        font-size: 16px;
        @media(max-width: 768px) {
            padding-top: 0px;
            padding-right: 0px;
            margin-top: -15px;
        }
        @media(min-width: 600px) {
            padding-top: 0px;
            padding-right: 0px;
            margin-top: -30px;
        }
        @media(min-width: 900px) {
            padding-top: 0px;
            padding-right: 0px;
            margin-top: -40px;
        }
        @media(min-width: 1200px) {
            padding-top: 12px;
            padding-right: 52px;
            margin-top: 0px;
        }
    }
    &__top {
        .inner-top {
            display: grid;
            grid-template-columns: 55.3px 1fr;
            width: 100%;
            height: 50%;
            transform: translate(0px, 0px);
            z-index: -1;
            @media(max-width: 768px) {
                position: static;
            }
            @media(min-width: 600px) {
                position: static;
            }
            @media(min-width: 900px) {
                position: static;
            }
            @media(min-width: 1200px) {
                position: absolute;
            }
            span {
                background-color: #6C3E37;
               
                text-transform: uppercase;
                color: #ffffff;
                position: relative;
                display: block;
                @media(max-width: 768px) {
                    font-size: 16px;
                    padding: 4px 4px;
                }
                @media(min-width: 600px) {
                    font-size: 25px;
                    padding: 5px 5px;
                }
                @media(min-width: 900px) {
                    font-size: 25px;
                    padding: 5px 5px;
                }
                @media(min-width: 1200px) {
                    font-size: 30px;
                    padding: 6px 10px 10px 10px;
                }
                @media(min-width: 1400px) {
                    padding: 5px 10px 8px 10px;
                }
                &:first-child {
                    span {
                        background-color: #D0632D;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }
            }
        }
    }
    &__img {
        width: 100%;
        @media(min-width: 1200px) {
            transform: translate(0px, 2px);
        }
        @media(min-width: 1400px) {
            transform: translate(0px, -12px);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media(max-width: 768px) {
                position: relative;
                top: -28px;
            }
            @media(min-width: 600px) {
                position: relative;
                top: -55px;
            }
            @media(min-width: 900px) {
                position: relative;
                top: -50px;
            }
            @media(min-width: 1200px) {
                top: 0px;
            }
        }
    }
    .contentDiv {
        left: 0px;
        @media(max-width: 768px) {
            position: static;
            min-width: auto;
        }
        @media(min-width: 600px) {
            position: static;
            min-width: 300px;
        }
        @media(min-width: 900px) {
            position: static;
            min-width: 350px;
        }
        @media(min-width: 1200px) {
            min-width: 400px;
            position: absolute;
        }
    }
    .grid-wrap {
        display: grid;
        grid-template-columns: 5fr 1fr;
        @media(max-width: 768px) {
            grid-template-columns: 1fr;
        }
        @media(min-width: 600px) {
            grid-template-columns: 1fr;
        }
        @media(min-width: 900px) {
            grid-template-columns: 5fr 1fr;
        }
        @media(min-width: 1200px) {
            grid-template-columns: 3fr 1fr;
        }
        .col-grid {
            &:nth-child(1) {
                position: relative;
            }
            &:nth-child(2) {
                background-color: #8DC73F;
                position: relative;
                @media(max-width: 768px) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                &::before {
                    content: '';
                    background-color: #8DC73F;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    right: -100%;
                    @media(max-width: 768px) {
                        display: none;
                    }
                    @media(min-width: 600px) {
                        display: none;
                    }
                    @media(min-width: 900px) {
                        display: block;
                    }
                    @media(min-width: 1200px) {
                        display: block;
                    }
                }
            }
        }
    }
}

.rnd-landing {
    padding: 0px 0px;
    .pDesc {
        font-size: 24px;
        font-weight: 500;
        color: $darkgreen;
        letter-spacing: -0.8px;
        line-height: 36px;
        @media(max-width: 768px) {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.4px;
            padding-right: 0px !important;
        }
        @media(min-width: 600px) {
            padding-right: 2rem !important;
        }
        @media(min-width: 600px) {
            padding-right: 3rem !important;
        }
        @media(min-width: 1200px) {
            padding-right: 15px !important;
        }
    }
    .title-left-line  {
        &.mb-3 {
            @media(max-width: 768px) {
                margin-bottom: 0px !important;
            }
        }
    }
    .section-div2  {
        &.mt-5 {
            @media(max-width: 768px) {
                margin-top: 0rem !important;
            }
        }
    }
}
