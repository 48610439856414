h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: "Playfair", Arial, sans-serif;
    // font-family: Arial, sans-serif;
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 600;
}

p {
    font-size: 16px;
    color: #444;
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 30px;
}
section {
    // padding: 2em 0;
    padding: 75px 0;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    &.linear-top {
        background-image: linear-gradient(180deg, #d7fbb3 -50%, transparent 70%);
        &.cream {
            background-image: linear-gradient(180deg, #fff3dd -50%, transparent 70%);
        }
    }
    &.linear-bottom {
        background-image: linear-gradient(0deg, #d7fbb3 -50%, transparent 50%);
    }
    @media screen and (max-width: 575px) {
        padding: 40px 0;
    }
}
.btn {
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    padding: 1em 2.5em;
    font-weight: 900;
    border-radius: 100vmax;
    background-color: $orange;
    background-image: linear-gradient(75deg, $orange 50%, white 65%, #e87f03 75%);
    color: white;
    cursor: pointer;
    background-size: 350%;
    transition: all 0.5s ease;
    &:hover:not(:disabled) {
        box-shadow: rgb(100 100 111 / 37%) 0px 7px 29px 0px;
        background-position: 100% 0;
    }
    &:disabled{
        background-color: #e8e8e8;
        background-image:  none;
    }
    &.outline {
        background-color: transparent;
        border: 1px solid $orange;
        background-image: unset;
        color: $orange;
    }
}
.container {
    width: unset;
}

.arrowButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    background-color: white;
    border: none;
    outline: none;
    box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1);
    transition: all 0.35s ease-in-out;
    @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
        &:before {
            font-size: 75%;
        }
    }
    &:before {
        color: $orange;
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: center;
        transition: all 0.35s ease-in-out;
    }
    &.slick-prev,
    &.prev {
        &:before {
            content: "\f053";
        }
    }
    &.slick-next,
    &.next {
        &:before {
            content: "\f054";
        }
    }
    &.square {
        border-radius: unset;
    }
    &.no-shadow {
        box-shadow: unset;
    }
    &.no-bg {
        background-color: transparent;
    }
    &:hover {
        background-color: $orange;
        &:before {
            color: white;
        }
    }
}

//slickjs
.slick-slider,
.slick-initialized,
.slick-list,
.slick-track {
    height: 100%;
}
.slick-slider .slick-track {
    margin: auto;
}
.slick-slide {
    & > div {
        height: 100%;
        position: relative;
    }
    &:not(.slick-active) {
        // opacity: .5;
        // transition: .3s ease .1s;
        // pointer-events: none;
    }
}
.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &.slick-prev {
        left: 10px;
    }
    &.slick-next {
        right: 10px;
    }
}
.slick-dots {
    position: absolute;
    bottom: 30px;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex !important;
    align-items: baseline;
    align-items: center;
    justify-content: center;
    list-style: none;
    .slick-active {
        button {
            background-color: $orange;
        }
    }
    button {
        margin: 0 4px;
        border: 1px solid $orange;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: white;
        font-size: 0;
    }
    li {
        margin: 0;
    }
    @media screen and (max-width: 992px) {
        bottom: 15px;
        button {
            width: 8px;
            height: 8px;
            margin: 0 2px;
        }
    }
}

.container-slider {
    .slick-slide {
        overflow: hidden;
        transition: $transition;
        transition-delay: 0.6s;
        &:not(.slick-center) {
            transform: translateX(0em) scale(0.9);
        }
    }
    .slick-prev {
        transform: translateX(-50%);
    }
    .slick-next {
        transform: translateX(50%);
    }
}
//

.square {
    aspect-ratio: 1/1;
}

.object-cover {
    object-fit: cover;
}

.ul__list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
    font-size: 1.25rem;
    @media screen and (max-width: 575px) {
        justify-content: start;
        overflow-x: scroll;
        border-radius: 8px;
        border: 1px solid #f8f8f8;
        box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
    }
    li {
        margin: 0.5em 1em;
        transition: all .3s ease;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: -1.2px;
        &.active {
            a {
                color: $orange;
                border-bottom: 3px solid $orange;
            }
            color: $orange;
            border-bottom: 3px solid $orange;
        }
        &:hover{
            color: $orange;
        }
    }
    a {
        padding: 0.25em 0;
        font-weight: 600;
        transition: $transition;
        &:hover {
            color: $orange;
        }
    }
}

.page__wrapper {
    margin-top: 80px;
    // min-height: 100vh;
    @media screen and (max-width: 575px) {
        margin-top: 70px;
    }
}

.pagination {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        margin: 0 0.25em;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        // background-color: transparent;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
        transition: $transition;
        a {
            color: $orange;
            padding: 0.5em 1em;
            transition: $transition;
        }
        &.active,
        &:hover {
            background-color: $orange;
            cursor: default;
            a {
                color: white;
                cursor: default;
            }
        }
        @media screen and (max-width: 575px) {
            width: 35px;
            height: 35px;
            margin: 0 0.125em;
        }
        .nav {
            font-size: 80%;
        }
    }
}

ol,
ul {
    line-height: 1.5;
    padding-left: 5px;
    margin-bottom: 1em;
    li {
        margin-left: 1em;
        margin-bottom: 0.75em;
        padding-left: 0.25em;
    }
}
.flashmessage{
    background: #5cb85c;
    padding: 1em 1.5em;
    display: block;
    border-radius: 8px;
    margin-bottom: 1em;
    color: white;
    &.error{
        background: #c03d29;
    }
}
