.banner-home {
    height: 100vh;
    @media(max-width: 992px) {
        // min-height: 250px;
        width: 100%;
        height: unset;
        aspect-ratio: 16/8;
    }
    p.secondary-title {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -1.6px;
        @media(max-width: 768px) {
           font-size: 14px;
           letter-spacing: -0.6px; 
        }
    }
    h5.extra-big-title {
        font-size: 58px;
        font-weight: 700;
        letter-spacing: -2.9px;
        line-height: 66.7px;
        @media(max-width: 768px) {
            font-size: 18px;
            line-height: 1.3;
            letter-spacing: -1px;
        }
    }
}

//contact
.contat-form {
    background-color: white;
    padding: 2em;
    // border: 1px solid #e1e1e1;
    border-radius: 12px;
    box-shadow: 0px 0 15px -3px rgba(0, 0, 0, 0.1);
    label {
        display: block;
        font-weight: bold;
        text-transform: uppercase;
    }
    input,
    textarea {
        margin-top: 1em;
        font-size: 1rem;
        font-family: Arial, sans-serif;
        margin-bottom: 2em;
        width: -webkit-fill-available;
        width: -moz-available;
        padding: 1em 1.25em;
        border-radius: 5px;
        border: 1px solid #e1e1e1;
        outline: none;
        display: block;
        resize: vertical;
        transition: all .35s ease;
        &::-webkit-input-placeholder{
            color: #ccc;
        }
        &:-ms-input-placeholder{
            color: #ccc;
        }
        &::placeholder{
            color: #ccc;
        }
        &.error{
            border: 1px solid red;
        }
    }
}
.map__section{
    .info{
        background-color: $greenalt;
        color: white;
        padding: 2em;
        p {
            color: #ffffff;
        }
    }
    .contact__list{
        display: flex;
        align-items: baseline;
        // margin-bottom: 1em;
        margin-bottom: 8px;
        // .icon{
        //     width: 40px;
        // }
        &>*{
            min-width: 30px;
            margin: 0;
        }
        a{
            &:hover{
                text-decoration: underline;
            }
        }
    }
    hr{
        border: 1px solid;
        margin: 2em 0;
        opacity: 0.3;
    }
    .div__map{
        background-color: #e1e1e1;
        min-height: 475px;
        #map{
            height: 100%;
        }
    }
    .map__marker{
        width: 300px;
        font-size: .9rem;
        text-align: center;
        display: block;
        @media screen and (max-width: 575px) {
            display: contents;
        }
        a:hover{
            text-decoration: underline;
        }
    }
}


//about
.collapse__img{
    margin-bottom: 1em;
    text-align: center;
    img{
        object-fit: cover;
        margin: 0 .5em .5em;
        height: 250px;
        width: 100%;
        @media screen and (max-width: 767px) {
            height: 150px;
        margin: 0 auto .5em;
        }
        // &:nth-child(1), &:nth-child(4){
        //     width: 40%;
        // }
        // &:nth-child(2), &:nth-child(3), &:nth-child(5){
        //     width: 25%;
        // }
    }
}

.signature{
    width: 175px;
    margin-bottom: 1em;
}


section.jfood{
    text-align: center;
    @media screen and (max-width: 767px) {
        text-align: left;
    }
}
.j-left{
    text-align: right;
    @media screen and (max-width: 767px) {
        text-align: left;
    }
}
img.jfood{
    margin-bottom: 1em;
    width: 100%;
    object-fit: contain;
    @media screen and (max-width: 575px) {
        max-width: 250px;
    }
}


//recipe
.detail__recipe_img{
    height: 500px;
    width: 100%;
    @media screen and (max-width: 575px) {
        aspect-ratio: 4 / 3;
        height: unset;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.shareButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
        flex-direction: row;
    }
}


//rnd
.img__certification{
    width: 100%;
    margin-bottom: 1em;
    box-shadow: 0px 8px 11px -3px rgb(236 236 236);
}

.div__certification:hover > :not(:hover){
    opacity: .4;
}

.div__certification > div{
    transition: all .3s ease;
}

//pdct
.dried__wrap{
    $green: #249f51;
    border: 1px solid $green;
    background-color: white;
    margin-bottom: 1.5em;
    .dried__title{
        padding: 1em 2em;
        background-color: $green;
        font-size: 1.125rem;
        color: white;
        font-weight: 600;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3em;
    }
    .dried__body{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 150px;
            padding: 2em;
            object-fit: contain;
        }
    }
}

.content-refinery-left{
    ul{
        padding: 0;
        margin: 0;
        margin-bottom: 1em;
        margin-left: -5px;
        li{
            font-weight: 600;
            font-size: 1.125rem;
            color: #795243;
            margin-bottom: .5em;
            padding-left: .75em;
            &::marker{
                content: '\f111';
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                font-size: 60%;
            }
        }
    }
}

.jfood-bread-image{
    display: flex;
    justify-content: center;
    img{
        width: 70%;
        object-fit: contain;
        margin: 0 auto .75em;
    }
    .img__list{
        text-align: center;
        display: grid;
        align-self: self-end;
        // place-self: center;
        width: auto;
    }
}

.prod__img100{
    img{
        width: 100%;
    }
}

.space__wrapper{
    min-height: 100vh;
}

// About Landing

.about-landing,
.rnd-landing {
    h2 {
        color: #4B8474;
        padding-right: 16rem;
        @media(max-width: 768px) {
            padding-right: 0px;
        }
        @media(min-width: 600px) {
            padding-right: 15px;
        }
    }
    .div-grid {
        display: grid;
        grid-template-columns: 2.6fr 1fr;
        @media(max-width: 768px) {
            grid-template-columns: 1fr;
        }
        @media(min-width: 600px) {
            grid-template-columns: 2fr 1fr;
        }
    }
    .childrenSec {
        padding-left: 15px;
        @media(max-width: 768px) {
            padding-left: 0px;
        }
    }
}

.about-landing,
.product-landing {
    padding: 0px 0px 0px 0px;
}

.about-landing {
    padding: 50px 0px 0px 0px;
    h2 {
        font-weight: 600;
        @media(max-width: 768px) {
            font-size: clamp(1.4rem,1.125rem + .85vw,2rem);
            line-height: 1.5;
        }
    }
    .section-div2 {
        &.mt-5 {
            @media(max-width: 768px) {
                margin-top: 1rem !important;
            }
        } 
    }
}

.rnd-landing {
    p {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px; /* 150% */
        letter-spacing: -1.2px;
    }
    .childrenSec {
        p {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: normal;
            line-height: 30px;
            color: #444444;
        }
    }   
}

.product-landing {
    padding: 50px 0px 50px 0px;
}

.certification-landing {
    padding: 50px 0px 50px 0px;
    .third-title  {
        text-align: center;
    }
}

.about,
.contact {
    p.short-desc {
        font-size: 24px;
        color: #4B8474;
        font-weight: 500;
        line-height: 36px; 
        letter-spacing: -1.2px;
        @media(max-width: 768px) {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.4px;
        }
    }
}

.contact {
    p.short-desc {
        font-weight: 400;
    }
}

.about {
    padding: 30px 0px 0px 0px;
    .align-items-center {
        align-items: flex-start !important;
    }
    &__overview {
        .img-float {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 528px;
            float: left;
            width: 100%;
            padding-bottom: 0px;
            padding-right: 2%;
            margin-top: 8px;
            gap: 12px;
            @media (max-width: 768px) {
                max-width: 100%;
                padding-right: 0%;
            }
            img {
                height: 271px;
                @media(max-width: 768px) {
                    width: 100%;
                    margin-bottom: 15px;
                    height: auto;
                }
            }
       }
    }
    .collapse__img {
        margin-bottom: 15px;
        margin-top: 30px;
    
        img {
            margin: 0 0em 0em;
            height: 260px;
        }
        .row {
            display: grid;
            --bs-gutter-x: 0rem;
            margin-top: 0px;
            margin-right: 0px;
            margin-left: 0px;
            gap: 18px;
            @media(max-width: 768px) {
                grid-template-columns: 1fr;
                overflow: hidden;
            }
            @media(min-width: 600px) {
                grid-template-columns: 1fr 1fr;
            }
            @media(min-width: 900px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @media(min-width: 1200px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        .col-md-4 {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.about-ceo {
    padding: 30px 0px 0px 0px;
    h2 {
        font-weight: 500;
        font-size: 22px;
        color: #55B01D;
        line-height: 36px; 
        letter-spacing: -1.1px;
        text-transform: uppercase;
    }
    strong {
        font-weight: 700;
        display: block;
    }
}

.about-vision-mission,
.about-history {
    padding: 30px 0px 30px 0px;
}

.about-history {
    .card-inner {
        display: flex;
        flex-wrap: wrap;
        gap: 70px;
        @media(max-width: 768px) {
            flex-wrap: nowrap;
        }
    }
    .card-date {
        width: 15%;
        font-weight: 600;
        text-align: right;
        letter-spacing: -0.9px;
        @media(max-width: 768px) {
            text-align: left;
            margin-left: 20px;
        }
        p {
            font-size: 18px;
            color: #5AA18E;
        }
    }
    .card-desc {
        width: 70%;
        p {
            font-size: 14px;
        }
    }
}

.rnd,
.recipes,
.contact-information {
    padding: 30px 0px 0px 0px;
    .text-24px {
        @media(max-width: 768px) {
            font-size: 20px;
            letter-spacing: -0.4px;
        }
    }
}

.rnd {
    h5,
    p {
        text-align: left;
    }
    p.text-24px {
        letter-spacing: -1.2px;
        line-height: 36px;
        margin-bottom: 36px;
        @media(max-width: 768px) {
            letter-spacing: -0.4px;
            line-height: 30px;
        }
    }
    .third-title {
        font-weight: 700;
        margin-bottom: 25px;
    }
}

.rnd-certification {
    padding: 50px 0px 30px 0px;
    margin-top: 15px;
}

.recipes {
    &__flex {
        gap: 30px;
        .col-flex {
            @media(max-width: 768px) {
                text-align: center;
            }
            @media(min-width: 600px) {
                text-align: center;
            }
            @media(min-width: 900px) {
                text-align: left;
            }
            h1 {
                margin-bottom: 2px;
                &.text-40px {
                    @media(max-width: 768px) {
                        font-size: 34px;
                    }
                }
            }
            p {
                margin-bottom: 0px;
                line-height: 36px;
                &.text-24px {
                    @media(max-width: 768px) {
                        font-size: 20px;
                    }
                }
            }
            img {
                margin-bottom: 0px;
            }
            &:last-child {
                align-self: center;
            }
        }
    }
    .slick-slide  {
        p {
            color: #ffffff;
        }
    }
}

.recipes-detail {
    padding: 0px 0px;
    margin-top: -62px;
    overflow: visible;
    .share-social {
        margin-top: 6.5rem;
        @media(max-width: 768px) {
            margin-top: 0rem;
        }
    }
    &__desc {
        margin-top: 6.5rem;
        @media(max-width: 768px) {
            margin-top: 5rem;
        }
    }
}

.related-recipe {
    padding: 50px 0 50px 0;
    .title-left-line {
        &::before {
            display: none;
        }
    }
    .slick-list {
        // margin: 0 -15px;
    }
    .arrowButton  {
        &.slick-next {
            top: -46px;
            @media(max-width: 768px) {
                top: -35px;
            }
            @media(min-width: 600px) {
                top: -50px;
            }
        }
        &.slick-prev {
            top: -45px;
            right: 45px;
            left: inherit !important;
            @media(max-width: 768px) {
                top: -35px;
                right: 65px;
            }
            @media(min-width: 600px) {
                top: -50px;
            }
        }
    }
}

.contact {
    padding: 30px 0px 20px 0px;
}

.contact-information {
    .map__section  {
        margin-top: 30px
    }
}

.product {
    &.linear-top  {
        background-position: 0px 60px;
        background-image: linear-gradient(180deg, #F2FFE7 0%, rgba(247, 255, 240, 0.00) 80%);
        padding: 10px 0px 0px 0px;
    }
}

.about-ceo {
    // min-height: 750px;
}

.about-history {
    // min-height: 800px;
}

.table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    @media(max-width: 768px) {
        table-layout: auto;
    }
    thead {
        tr {
            td {
                font-size: 12px;
                font-weight: 700;
                color: #ffffff;
                padding: 10px 5px;
                border: 1px solid rgba(255, 255, 255, 0.25);
            }
            &:nth-child(1) {
                text-transform: uppercase;
                td {
                    font-size: 16px;
                    &:nth-child(1) {
                        border-top-left-radius: 10px;
                    }

                    &:nth-child(2) {
                        border-top-right-radius: 10px;
                    }
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-size: 16px;
                color: #444444;
                padding: 8px 15px; 
                border-top: 1px solid #DCDCDC;
                border-left: 1px solid #DCDCDC;
                border-right: 1px solid #DCDCDC;
                border-bottom: 1px solid #DCDCDC;
                i {
                    font-size: 20px;
                    color: #707070;
                    vertical-align: middle;
                }
            }
        }
    }
}

.table-striped-col {
    tbody {
        td {
            &:nth-child(odd) {
                background: #FBFBFB;
            }
            &:first-child {
                background: transparent;
            }
        }
    } 
}

.product {
    .text-36px {
        @media(max-width: 768px) {
            font-size: 26px;
            line-height: 30px;
        }
    }
    small.text-24px {
        @media(max-width: 768px) {
            font-size: 18px;
        }
    }
}