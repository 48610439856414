.history__div {
    position: relative;
    .history__wrap {
        // width: 50%;
        width: 100%;
        position: relative;
        left: 0;
        &:nth-child(even) {
            // left: 50%;
            .history__card {
                padding: 0 0 0 2.5em;
                .head__ {
                    &::before {
                        left: 0;
                    }
                }
            }
            .dot {
                @media(max-width: 768px) {
                    left: 0px;
                }
                @media(min-width: 600px) {
                    left: 140px;
                }
                @media(min-width: 900px) {
                    left: 175px;
                }
                @media(min-width: 1200px) {
                    left: 200px;
                }
                @media(min-width: 1400px) {
                    left: 232px;
                }
            }
        }
    }
    &:before {
        content: "";
        width: 1px;
        height: 96%;
        transform: translateX(-50%);
        background-color: #f0f0f0;
        position: absolute;
        top: 6px;
        // left: 50%;
        @media(min-width: 600px) {
            left: 140px;
        }
        @media(min-width: 900px) {
            left: 175px;
        }
        @media(min-width: 1200px) {
            left: 200px;
        }
        @media(min-width: 1400px) {
            left: 232px;
        }
    }
    .dot {
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: white;
        border: 1px solid #ff9921;
        border-radius: 50%;
        padding: 0;
        // left: 100%;
        top: 14px;
        transform: translateX(-50%) translateY(-50%);
        @media(min-width: 600px) {
            left: 140px;
        }
        @media(min-width: 900px) {
            left: 175px;
        }
        @media(min-width: 1200px) {
            left: 200px;
        }
        @media(min-width: 1400px) {
            left: 232px;
        }
        &.active {
            background-color: #ff9921;
            box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 0 3px #ff9921;
        }
    }
    .history__card {
        padding: 0 2.5em 0 0;
        margin-bottom: 1.5em;
        display: block;
        // box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        position: relative;
        left: 0;
        .head__ {
            padding: 0 2em;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            background-color: #5aa18e;
            font-size: 1.125rem;
            position: relative;
            display: flex;
            align-items: center;
            height: 40px;
            &::before {
                content: "";
                position: absolute;
                height: 12px;
                width: 12px;
                background-color: #5aa18e;
                transform: rotate(45deg) translateX(-75%);
                left: 100%;
                top: 50%;
            }
            p {
                line-height: 1;
                text-transform: uppercase;
                font-weight: bold;
                margin: 0;
                color: white;
            }
        }
        .body__ {
            padding: 1em 2em;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            background-color: white;
            border: 1px solid #f8f8f8;
            border-top: none;
            box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.065);
            p {
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding-left: 1em;
        &:before {
            transform: translateX(0);
            left: 1em;
        }
        .history__wrap {
            width: 100%;
            left: 0;
            &:nth-child(even) {
                left: 0;
            }
            .history__card {
                padding: 0 0 0 2.5em;
                .head__ {
                    &::before {
                        left: 0;
                    }
                }
            }
            .dot {
                left: 0;
            }
        }
    }
}
