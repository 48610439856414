.list-slider{
    .slick-slide{
        &>div{
            padding: .75em;
        }

    }
    .slick-arrow.slick-prev {
        left: -5px;
    }
    .slick-arrow.slick-next {
        right: -5px;
        @media(max-width: 768px) {
            right: 20px;
        }
    }
}

.slider-wrapper {
    padding: 0px 5px;
    margin-left: -2px;
    overflow: hidden;
    .slick-list {
        overflow: visible;
    }
}

// .slider-wrapper {
//     width: calc(100% + 30px);
//     padding: 0 5px;
//     margin-left: -2px;
//     // overflow: hidden;
//     .slick-list {
//         overflow: visible;
//     }
// }