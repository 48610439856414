.bannerWrap {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2em 0;
}
.bannerContent {
    background-color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: end;
    &>div{
        position: relative;
        bottom: 20px;
        @media screen and (max-width: 992px) {
            bottom: 40px;
        }
    }
}
.bannerDiv {
    width: 100%;
    // height: 100vh;
    height: 550px;
}

@media screen and (max-width: 992px) {
    .bannerDiv {
        height: unset;
        min-height: 250px;
        aspect-ratio: 16 / 10;
    }
    .bannerSecond {
        font-size: clamp(1.1rem, 1rem + 1vw, 0.4rem);
        font-size: 14px;
    }
    .bannerMain {
        font-size: clamp(1.2rem, 1.17rem + 1.1vw, 1.73rem);
        font-size: 18px;
    }
    .bannerDesc {
        font-size: 12px;
    }
    .bannerContent {
        padding-top: 1em;
    }
}
