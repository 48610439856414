$green: #bccb4c;
$greenSage: #5aa18e;
$darkgreen: #4B8474;
$greenalt: #8DC73F;
$black: #444;
$orange: #ff9921;
$blackLight: #666666;
$grey: #a8a8a8;
$transition: all 0.35s ease;

.main-title {
    font-size: clamp(1.76rem, calc(1.27rem + 1.45vw), 2.73rem);
    line-height: 1.3;
}
.secondary-title {
    font-size: clamp(1.4rem, calc(1.125rem + 0.85vw), 2rem);
    line-height: 1.5;
}
.secondary-title-alt-md {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
}
.third-title {
    font-size: clamp(0.94rem, calc(0.88rem + 0.28vw), 1.13rem);
    line-height: 1.6;
}
.big-title {
    font-size: clamp(1.88rem, calc(1.6rem + 1.39vw), 2.81rem);
}
.extra-big-title {
    font-size: clamp(1.88rem, calc(2.5rem + 1.39vw), 4.5rem);
    line-height: 1.3;
}
.extra-big-title-alt {
    font-size: 58px;
    letter-spacing: -2.8px;
    line-height: 66.7px;
}
.title-left-line {
    font-size: 40px;
    letter-spacing: -2px;
    @media(max-width: 768px) {
        font-size: 25px;
    }
    span {
        background-color: #ffffff;
        padding-right: 55px;
        display: inline-block;
        position: relative;
    }
    &::before {
        content: '';
        background-color: rgba(141, 199, 63, 0.50);
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translate(0%, -50%);
    }
}
.small-title {
    font-size: 0.9rem;
}
.font-light {
    font-weight: 200;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 500;
}
.font-medium {
    font-weight: 600;
}
.font-bold {
    font-weight: bold;
}

.border-top-right-radius-0px  {
    border-top-right-radius: 0px !important;
}

.border-top-left-radius-0px  {
    border-top-left-radius: 0px !important;
}

.border-top-right-radius-10px  {
    border-top-right-radius: 10px !important;
}

.border-top-2px {
    border-top: 2px solid #DCDCDC !important;
}

.border-bottom-2px {
    border-bottom: 2px solid #DCDCDC !important;
}

.border-right-white-2px {
    border-bottom: 2px solid #ffffff !important;
}

.border-right-2px {
    border-right: 2px solid #DCDCDC !important;
}

.border-bottom  {
    border-bottom: 1px solid #DCDCDC !important;
}

.border-left {
    border-left: 1px solid #DCDCDC !important;
}

.border-right {
    border-right: 1px solid #DCDCDC !important;
}

.border-left-none {
    border-left: none !important;
}

.border-right-none {
    border-right: none !important;
}

.border-bottom-none {
    border-bottom: none !important;
}

.grid-tc-1fr-1fr {
    grid-template-columns: 1fr 1fr;
}

.span-8px-0px {
    padding: 8px 0px;
}

.visibility-hidden {
    visibility: hidden;
}

$colors: (
    "green"     : $green,
    "greensage" : $greenSage,
    "darkgreen"  : $darkgreen,
    "greenalt" : $greenalt,
    "orange"    : $orange,
    "black"     : $black,
    "blacklight": $blackLight,
    "grey"      : $grey,
    "greyalt"   : #F2F2F2,
    "greylight" : #FBFBFB,
    "white"     : white,
    "greenlight": #f2ffe7,
    "brown"     : #795243,
    "brownalt"  : #A36826,
    "brownpwdr" : #C76536,
    "red"       : #E60013,
    "redalt"    : #F73028,
    "redpwdr"   : #C92C25
);

@each $name, $color in $colors {
    .text-#{$name} {
        color: $color !important;
    }
    .bg-#{$name} {
        background-color: $color !important;
    }
}

$aligns: (
    "left": left,
    "center": center,
    "right": right,
);

@each $name, $align in $aligns {
    .text-#{$name} {
        text-align: $align;
    }
}

.width-800 {
    width: min(100% - 15px, 800px) !important;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.w-50px {
    width: 50px;
}

.w-70px {
    width: 70px;
}

.w-230px {
    width: 230px;
}

.h-100 {
    height: 100%;
}

.h-300px {
    height: 300px;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.gap-15px {
    gap: 15px;
}

.gap-25px {
    gap: 25px;
}

.pl-1rem {
    padding-left: 1rem;
}

.mt-15px {
    margin-top: 15px !important;
}

.mb-15px {
    margin-bottom: 15px !important;
}

.mr-8px {
    margin-right: 8px !important;
}
 
.mobile-only {
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
    }
}
.dekstop-only {
    display: block;
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.text-link {
    color:  $orange;
    &:hover{
        text-decoration: underline;
    }
}

.text-12px {
    font-size: 12px !important;
}

.text-16px {
    font-size: 16px !important;
}

.text-18px {
    font-size: 18px !important; 
}

.text-24px {
    font-size: 24px;
}

.text-30px {
    font-size: 30px;
}

.text-36px {
    font-size: 36px;
}

.text-40px {
    font-size: 40px;
}

.list-style-none {
    list-style: none;
}

.text-center {
    text-align: center !important;
}

.text-spacing-min-0-9px {
    letter-spacing: -0.9px;
}

.text-spacing-min-1-8px {
    letter-spacing: -1.8px;
}

.text-spacing-min-1-2px { 
    letter-spacing: -1.2px;
}

.text-spacing-min-2px {
    letter-spacing: -2px;
}

.position-relative{
    position: relative;
}

.column-2{
    column-count: 2;
}
.column-3{
    column-count: 3;
}

.mbs-start-m-5px {
    margin-block-start: -5px;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-for-responsive {
    margin-bottom: 30px;
}

.div-thead,
.div-tbody {
    div {
        padding: 8px 15px;
    }
}

.div-tbody {
    div {
        border-left: 1px solid #DCDCDC;
        border-right: 1px solid #DCDCDC;
        border-bottom: 1px solid #DCDCDC;
        padding: 8px 15px;
    }
}

.div-tbody-title {
    margin-top: 15px;
}
.div-thead-title {
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    div {
        border-left: 1px solid rgba(255, 255, 255, 0.25);
        border-right: 1px solid rgba(255, 255, 255, 0.25);
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
}

.div-thead-title,
.div-tbody-title {
    background-color: $orange;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px 15px;
}

.div-thead-title,
.div-tbody-title {
    text-align: center;
    font-weight: bold;
    padding: 8px 15px;
}