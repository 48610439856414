.bannerWrap {
    position: relative;
    width: 100%;
    height: 100%;
}
.bannerContent {
    background-color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    
}

    .withHeading{
@media screen and (max-width: 769px) {

    background-size: 85%;
background-position: center right;
    }
}

.bannerDiv {
    width: 100%;
    height: 100vh;
    min-height: 400px;
    @media screen and (max-width: 575px) {
        margin-top: 70px;
    }
}

@media screen and (max-width: 992px) {
    .bannerDiv {
        height: unset;
        min-height: unset;
        aspect-ratio: 16/9.5;
    }
    .bannerSecond {
        font-size: clamp(1.1rem, 1rem + 1vw, 0.4rem);
        font-size: 14px;
    }
    .bannerMain {
        font-size: clamp(1.2rem, 1.17rem + 1.1vw, 1.73rem);
        font-size: 18px;
    }
    .bannerDesc {
        font-size: 12px;
    }
    .bannerContent {
        // padding-top: 1em;
    }
}
